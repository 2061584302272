import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// vant 组件
import Vant from 'vant';
import 'vant/lib/index.less';
import 'amfe-flexible'
import {translate} from "@/utils/translate";
import VueI18n  from 'vue-i18n';
import {getAppLanguage, getAppDefaultLanguage} from "@/utils/init";
import {webLog} from "@/api/log/log";

// axios 组件
import axios from 'axios'

Vue.use(Vant, {
    lazyComponent: true,
});  // 注册Vant
Vue.config.productionTip = false
Vue.prototype.$http = axios // 注册 axios
axios.defaults.baseURL = '/proxy_url'

import 'video.js/dist/video-js.css';

import Video from 'video.js'
import 'video.js/dist/video-js.css'

import './loading.css'
Vue.prototype.$video = Video

console.log(getAppDefaultLanguage(), getAppLanguage())
Vue.prototype.$app_country = getAppDefaultLanguage()

Vue.use(VueI18n);
const i18n = new VueI18n({
    locale: getAppLanguage() ? getAppLanguage() : 'en', // 默认语言
    messages: translate
});

// 错误信息上报
Vue.config.errorHandler = (err) => {
    webLog({error: err.stack})
}

const app = new Vue({
    i18n,
    router,
    store,
    render: h => h(App),
    beforeCreate() {
        // userInit()
    },
})

app.$mount('#app')
