import request from '@/utils/requests'
import qs from 'qs'

// 错误上报
export const webLog = data => {
    return request({
        url: '/log/web',
        method: 'post',
        data:qs.stringify(data)
    })
}
