import { init } from '@/api/lnicio/init'
import Fingerprint2 from "fingerprintjs2";

// 用户初始化
export const userInit = () => {
    const options = {
        fonts: {
            extendedJsFonts: true,
        },
        excludes: {
            audio: true,
            enumerateDevices: true,
            touchSupport: true,
        },
    };

    if (! localStorage.getItem('murmur')) {
        if (window.requestIdleCallback) {
            requestIdleCallback(function () {
                Fingerprint2.get(options, (components) => {
                    const values = components.map(component => component.value);
                    let murmur = Fingerprint2.x64hash128(values.join(''), 31);
                    localStorage.setItem('murmur', murmur);

                    // 进行INIT操作
                    init({
                            invited_by: '',
                            third_id: '',
                            third_app_id: '',
                            is_install: '',
                        }).then(res => {
                        localStorage.setItem('token', res.result.user_info.token)
                        localStorage.setItem('ueserInfo', JSON.stringify(res.result.user_info))
                        localStorage.setItem('sys_config', JSON.stringify(res.result.sys_conf))
                    })
                })
            })
        } else {
            Fingerprint2.get(options, (components) => {
                const values = components.map(component => component.value);
                let murmur = Fingerprint2.x64hash128(values.join(''), 31);
                localStorage.setItem('murmur', murmur);

                // 进行INIT操作
                init({
                    invited_by: '',
                    third_id: '',
                    third_app_id: '',
                    is_install: '',
                }).then(res => {
                    localStorage.setItem('token', res.result.user_info.token)
                    localStorage.setItem('ueserInfo', JSON.stringify(res.result.user_info))
                    localStorage.setItem('sys_config', JSON.stringify(res.result.sys_conf))
                })
            })
        }
    }
}

export const setLocalStorage = (key, val, expire) => {
    localStorage.setItem(key, JSON.stringify({
        expire: expire,
        val: val
    }))
}

export const getLocalStorage = (key, now) => {
    var str = localStorage.getItem(key)
    if (! str) {
        return false
    }
    var data = JSON.parse(str)
    if (data.expire < now) {
        return false
    }
    return data.val
}
// 获取当期的时间戳
export const getNowTimestamp = () => {
    return Math.floor(Date.now() / 1000);
}

// 获取设置的语言
export const getAppLanguage = () => {
    let default_language = getAppDefaultLanguage()
    return localStorage.getItem('appLanguage') ? localStorage.getItem('appLanguage') : default_language
}

export const isSetAppLanguage = () => {
    return localStorage.getItem('appLanguage') ? true : false
}

// 获取app默认语言
export const getAppDefaultLanguage = () => {
    let app_id = getAppId()
    let app_id_language_dict = {
        'ppcineweb': 'en',
        'ppcinewebpt': 'pt',
        'ppcinewebin': 'hi',
        'ppcinewebes': 'es',
    }

    return app_id_language_dict[app_id]

    // let hostname_suffix = getHostnameSuffix()
    // let country_dist = {
    //     'tv': 'hi',
    //     'vip': 'en',
    //     'cn': 'pt',
    //     'com': 'es',
    //     'localhost': 'hi'
    // }
    //
    // return country_dist[hostname_suffix] ? country_dist[hostname_suffix] : 'en'
}

export const getCurrentDateAsYYYYMMDD = () => {
    // 创建一个新的Date对象，它默认为当前日期和时间
    const now = new Date();

    // 获取年、月、日
    // 注意：getMonth() 返回的是0到11之间的数（0代表1月，11代表12月），因此需要+1
    // getDate() 返回的是1到31之间的数，表示一个月中的第几天
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // 确保月份是两位数
    const day = String(now.getDate()).padStart(2, '0'); // 确保日期是两位数

    // 将年、月、日拼接成YYYYMMDD格式
    return `${year}${month}${day}`;
}

export const getCurrentHour = () => {
    // 创建一个新的Date对象，它默认为当前日期和时间
    const now = new Date();

    return String(now.getHours()).padStart(2, '0');
}

export const getCurrentMinuteInt = () => {
    // 创建一个新的Date对象，它默认为当前日期和时间
    const now = new Date();

    return now.getMinutes()
}

// 获取域名后缀
export const getHostnameSuffix = () => {
    let hostname = window.location.hostname
    let hostnameList = hostname.split('.')
    return hostnameList.pop()
}

// 获取app_id
export const getAppId = () => {
    let hostname = window.location.hostname
    let hostname_dict = {
        'www.donkeyhd.tv': 'ppcinewebpt',
        'enqdj8mcr54u.8vy4.com': 'ppcineweb',
        'hitcp7bjeyrm.8vy4.com': 'ppcinewebin',
        'ptxm8z6mggwh.8vy4.com': 'ppcinewebpt',
        'enbbe7xs2dt5hz.8vy4.com': 'ppcineweb',
        'www.8vy4.com': 'ppcineweb',
        'es8b4kt2qgmv.8vy4.com': 'ppcinewebes',
        'es5errngbvk8.k5ca.com': 'ppcinewebes',
        'nz4puz2m66w.k5ca.com': 'ppcineweb',
        'enbbuc76b5mqpj.k5ca.com': 'ppcineweb',
        'hiqacgdme53f.k5ca.com': 'ppcinewebin',
        'ptm2qsctmk2c.k5ca.com': 'ppcinewebpt',
        'en.8vy4.com': 'ppcineweb',
        'enb.8vy4.com': 'ppcineweb',
        'pt.8vy4.com': 'ppcinewebpt',
        'hi.8vy4.com': 'ppcinewebin',
        'es.8vy4.com': 'ppcinewebes',
        'hi.k5ca.com': 'ppcinewebin',
        'es.k5ca.com': 'ppcinewebes',
        'pt.k5ca.com': 'ppcinewebpt',
        'en.k5ca.com': 'ppcineweb',
        'www.k5ca.com': 'ppcineweb',
        'localhost': 'ppcinewebin',
    }
    if (hostname_dict[hostname]) {
        return hostname_dict[hostname]
    }

    let hostname_suffix = getHostnameSuffix()
    let hostname_suffix_dict = {
        'vip': 'ppcineweb',
        'tv': 'ppcinewebin',
        'cn': 'ppcinewebpt',
        'com': 'ppcinewebes',
        'localhost': 'ppcinewebes',
    }

    return hostname_suffix_dict[hostname_suffix] ? hostname_suffix_dict[hostname_suffix] : 'ppcineweb'
}
